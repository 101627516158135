import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 4
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"FOUR"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={
          "True or false: there is one strategy that can be used to manage behavior for all girls."
        }
        answers={["True", "False"]}
        correctAnswerNumber={1}
        answerDetail={
          "Every girl is different and will respond to different strategies in different ways at different times. This is why it is so important for us to build strong relationships with our players so we understand what they respond to and how we can best communicate and support them. "
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 4, 4)
